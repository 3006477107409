
@import 'variables';
@import 'bootstrap/scss/bootstrap';
@import 'app';
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tiny5&display=swap');
body {
/*	font-family: 'Zen Kaku Gothic Antique', sans-serif;*/
font-family: "Roboto Mono", monospace;
  font-optical-sizing: auto;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
font-size: 15px;
line-height: 1.6em;
margin:  0;
color:  #000;
p {
	margin-bottom: 2em;
}
}

.top {
	position: relative;
	width:  100%;
	background: black;
/*	background:  rgba($bg, 1);*/
	margin: 0 0 40px 0;
	height:  200px;
/*	color:  $orange2;*/
	color: black;
}
h4 {
	font-size: 16px;
}
.content {
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
	padding:  0 10px 0 5px;
	box-sizing:  border-box;
	color: #444745;
	strong {
		color:  black;
	}
	a {
		color: black;
/*		color:  $purple3;*/
	}
	#description {
		margin-top: 20px;
		padding-top: 20px;
		border-top: 1px solid #ccc;
		margin-bottom:  50px;
	}
}

#headshot {
	float:  right;
	max-width:  200px;
	width:  30%;
	min-width:  140px;
	margin:  10px 0px 10px 20px;
/*	box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);*/
    filter: grayscale(1) contrast(1.2);
    border: 2px solid #000;
    padding: 5px;
}

/* PROFILE */
#profile {
	position: absolute;
	top:  0;
	left:  0;
	width:  100%;
	padding: 10px 5px 5px;
	margin:  0 auto;
	text-align:  center;
	/*background: rgba($bg, 0.5);*/
	z-index: 99;
/*	color:  $yellow;*/
	color: black;
	transition: all 100ms cubic-bezier(0.250, 0.250, 0.750, 0.750); /* linear */
	transition-timing-function: cubic-bezier(0.250, 0.250, 0.750, 0.750); /* linear */
}
#profile:hover {
	opacity:  0.8;
}
h1 {
	display:  inline-block;
	font-family:  'Tiny5', Arial;
	font-size:  35px;
	line-height:  30px;
	margin:  0 10px 0 0;
/*	color:  $yellow !important;*/
	color: white;
}
#title {
	display:  inline-block;
	width:  200px;
	line-height: 15px;
	font-size:  12px;
	font-weight: bold;
	text-align:  left;
	margin:  3px 0;
/*	color:  $orange3;*/
color: white;
	font-weight:  300;
}
#title span {
/*	color: $orange3;*/
color: white;
}
@media screen and (max-width: 460px) {
	#title {
		text-align: center;
	}
}

/* HEADER / FOOTER */

#headers {
	position: absolute;
	top:  100%;
	left:  0;
	width:  100%;
	text-align:  center;
/*	background:  rgba(255,255,255,0.8);*/
}
a.headerItem {
	text-decoration: none !important;
}
.headerItem {
	display:  inline-block;	
	padding: 4px 4px;
	font-size: 15px;
	margin:  0;
/*	color:  $purple3;*/
color: #999;
	cursor: pointer;
}
.headerItem:hover {
	color: black;
/*	color: $purple1;*/
}
.headerItem.selected {
/*	color: $purple1;*/
color: black;
	font-weight:  bold;
	text-decoration: underline;
}
#visualContainer {
	position: absolute;
	left:  0px;
	top:  0px;
	width:  100%;
	height:  100%;
	overflow:  hidden;
}
.cell {
	position: absolute;
	width:  20px;
	height: 20px;
	transition: all 500ms cubic-bezier(0.250, 0.250, 0.750, 0.750); /* linear */
	transition-timing-function: cubic-bezier(0.250, 0.250, 0.750, 0.750); /* linear */
}
footer {
	margin-top:  40px;
	padding:  50px 0;
/*	background:  rgba($bg, 1);*/
background: black;
	color: white;
	.content a {
		color:  white;
	}
}
.contact {
	text-align:  center;
	span {
		margin:  0 10px;
	}
}
/* STORY ITEM */
h2 {
	font-size:  33px;
	font-weight:  900;
/*	color: $purple1;*/
color: black;
margin-top: 20px;
	/*text-transform: uppercase;*/
}
.sectionHed {margin: 70px 0 20px;}
.storyItem {
	width: 100%;
	margin: 20px 0 60px;
}
.storyItem h3 {
	margin-bottom: 0px;
	font-size: 23px;
	line-height:  27px;
	font-weight:  900;
}
h3 .storyHed {
/*	color: $purple2;*/
	border-bottom: none !important;	
}
.storyHed.lessonHed {
	margin-top: 50px;
}
.storyItem  a {
	text-decoration: none !important;
	/*color: $purple1;*/
	border-bottom: 1px dashed #333;
}
.storyItem  a:hover {
	color: black;
	border-bottom: 2px solid #000;
}
.publication {
	font-weight: 800;
	font-size: 14px;
	text-transform: uppercase;
	margin-top: 1px;
/*	color: $purple3;*/
	opacity: 0.7;
}
.storyDesc {
	margin: 20px 0 13px;
}
.storyImage {
	width: 100%;
	border: 1px solid #666;
	/*box-shadow: 8px 8px 0px 0px #333;*/
}
.travelimage {
	width: 100%;
	border: 0px solid #000;
	max-width: 500px;
	margin: -40px auto -10px !important;
	text-align: center;
	display: block;
}
.smaller.storyImage {
	width: 70%;
	margin-left: 15%;
	margin-top: 20px;
}
.iframeContainer {
	overflow: hidden;
	padding-top: 56.25%;
	position: relative;
	border: 1px solid $purple3;
	/*box-shadow: 8px 8px 0px 0px #333;*/
	background: black;
}
.iframeContainer iframe {
	border: 0;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}
.storyItem  a.nounderline {
	border-bottom: none !important;
}
.storyImage:active {
	box-shadow: 0px 0px 0px 0px #333;
	margin-left: 3px;
	top: 3px;
	position: relative;
}

.comicContainer {
	display:  block;
	width:  100%;


	margin:  10px 0 30px;
	img {
		width:  100%;
		display:  block;
	}
}

/* LATEST */
#newsletter {
	max-width: 500px;
	margin: 0px auto 30px;
	text-align: center;
	padding: 10px;
}
#newsletter p {
	margin-bottom: 3px;
	font-size: 14px;
	margin: 0 0 0 2px;
	color: #000;
}
.newsletter_button {
	display: inline-block;
	text-align: center;
	padding: 10px;
/*	background: #972844;*/
	background: #ebd8e3;
	margin: 0 auto;
	font-size: 18px;
	width: 200px;
/*	border-radius: 6px;*/
	cursor: pointer;
	box-shadow: 2px 2px 0 2px #000;
	margin-bottom: 2px;
}
.newsletter_button a {
	color: white !important;
	text-decoration: none !important;
}
.newsletter_button:hover {
	opacity: 0.9;
}
.newsletter_button:hover a {
	text-decoration: underline !important;	
}
.newsletter_button:active {
	box-shadow: 0px 0px 0 0px #000;
	margin-left: 2px;
	margin-top: 2px;
	margin-bottom: 0px;
}
#latest {	
	display: grid;
	grid-row: auto auto;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
	gap: 10px;
}
.latestItem {
	align-items: center;
	justify-content: center;
	display:flex;
	position: relative;
	a {
		text-decoration: none !important;
	}
	overflow: hidden;
	border: 1px solid #000;
}

.latestItem::after {
	box-shadow: inset 0em 0em 3em rgba(0,0,0,0.4);
	content: '';
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
.latestItem:hover::after  {
	box-shadow: inset 0em 0em 0em rgba(0,0,0,0.4);
}
.latestItem:active::after {
	box-shadow: inset 0em 0em 5em rgba(0,0,0,0.8);
}
.latestImage {
	width: 100%;
	display: block;
}
.latestItem .hed {
	line-height: 16px;
	font-size: 20px;
	font-weight: bold;
	display: block;
	width: 100%;
	color: black;
	font-weight: bold;
	.pub {
		font-size: 15px;
		color: #bbb;
		text-transform: uppercase;
		line-height: 12px;
		margin-top: 7px;
	}
}
